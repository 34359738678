/* You can add global styles to this file, and also import other style files */
@import '~@aws-amplify/ui-angular/theme.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.spacer {
  flex: 1 1 auto;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 49.5%;
}

.mat-card {
  box-shadow: none !important;
}

.mat-card-actions {
  margin: 0 0.1em 0 0.1em !important;
}

.mat-card-actions-result-message {
  color: #00800096 !important;
}

.mat-option {
  line-height: normal !important;
}

.red-icon {
  color: red;
}

.green-icon {
  color: green;
}

.spinner {
  margin: 5.19em;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  flex: 1;
  margin-right: 20px;
}

.col:last-child {
  margin-right: 0;
}

.full-width-card {
  width: 100%;
}
