@use '@angular/material' as mat;

$magna-primary: mat.define-palette(mat.$grey-palette, 900);
$magna-accent: mat.define-palette(mat.$grey-palette, A700, A100, A400);
$magna-accent-2: mat.define-palette(mat.$grey-palette, 600);
$magna-warn: mat.define-palette(mat.$yellow-palette, 900);

$magna-theme: mat.define-light-theme(
  (
    color: (
      primary: $magna-primary,
      accent: $magna-accent,
      warn: $magna-warn,
    ),
  )
);

.magna-description {
  color: mat.get-color-from-palette($magna-accent) !important;
}

.mat-progress-bar {
  color: mat.get-color-from-palette($magna-accent) !important;
}

$magna-typography: mat.define-legacy-typography-config(
  $headline: mat.define-typography-level(3rem, 1, 700),
);

@include mat.all-legacy-component-themes($magna-theme);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($magna-typography);`
@include mat.all-legacy-component-typographies($magna-typography);
@include mat.legacy-core();
